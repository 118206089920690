/* eslint-disable */
import $ from 'jquery';
import anime from 'animejs/lib/anime.es.js';
import FullPage from 'fullpage.js';
import noUiSlider from 'nouislider';
import wNumb from 'wnumb';
import Inputmask from "inputmask";
import Typed from 'typed.js';
import Swiper from 'swiper';
import { Fancybox } from '@fancyapps/ui';

window.$ = $;
window.jQuery = $;

// slider works
$(() => {
    const speed = 5000;

    new Swiper(".slider-projects", {
        autoplay: {
            delay: 3500
        },
        slidesPerView: 5,
        spaceBetween: 50,
        breakpoints: {
            320: {
                spaceBetween: 15,
                slidesPerView: 1
            },
            991: {
                slidesPerView: 5,
                spaceBetween: 50
            }
        }
    });
});

// scroll
$(() => {
    Inputmask({ mask: "+7 (999) 999-99-99", showMaskOnHover: false }).mask('input[name=phone]');

    if (window.matchMedia('(min-width: 990px)').matches) {
        // const fullpage = new FullPage('#fullpage');

        // $('.start .btn').on('click', (e) => {
        //     e.preventDefault();
        //     fullpage_api.moveTo('callback');
        // });

        $('.works .swiper-slide').hover(
            () => {
                fullpage.setMouseWheelScrolling(false);
            },
            () => {
                fullpage.setMouseWheelScrolling(true);
            }
        );
    } else {
        $('.start .btn').on('click', function(e) {
            e.preventDefault();
            $('html, body').animate({
                scrollTop: $(".callback").offset().top
            }, 2000);
        });
    }

    new Typed('.typed', {
        strings: ["быстрый", "удобный", "красивый"],
        typeSpeed: 100,
        backSpeed: 50,
        loop: true,
        backDelay: 1000,
    });
});

// calc
$(() => {
    const range_all_sliders = {
        'min': [ 0 ],

        'max': [ 300000 ]
    };
    
    const pipsSteps = document.getElementById('pips-steps');
    
    noUiSlider.create(pipsSteps, {
        range: range_all_sliders,
        connect: true,
        start: [0, 75000],
        step: 5000,
        pips: {
            mode: 'steps',
            // density: 3,
            stepped: true,
            filter: (value, type) => {
                if (type === 2) {
                    return value % 12500 ? 0 : 2;
                }
                return value % 75000 ? -1 : 1;
            },
            format: wNumb({
                decimals: 0,
                suffix: ' ₽',
                thousand: ' '
            })
        }
    });

    pipsSteps.noUiSlider.on('update', function (values) {
        const format = wNumb({
            decimals: 0,
            suffix: ' ₽',
            thousand: ' '
        });
        $('.steps__wrapper input[name=summ]').val(`${format.to(+values[0])} - ${format.to(+values[1])}`);
        $('.step .summ span').text(`${format.to(+values[0])} - ${format.to(+values[1])}`);
    });
});

// start animation
$(() => {
    function fitElementToParent(el, padding) {
        var timeout = null;

        function resize() {
            if (timeout) clearTimeout(timeout);
            anime.set(el, {
                scale: 1
            });
            var pad = padding || 0;
            var parentEl = el.parentNode;
            var elOffsetWidth = el.offsetWidth - pad;
            var parentOffsetWidth = parentEl.offsetWidth;
            var ratio = parentOffsetWidth / elOffsetWidth;
            timeout = setTimeout(anime.set(el, {
                scale: ratio
            }), 10);
        }
        resize();
        window.addEventListener('resize', resize);
    }

    var advancedStaggeringAnimation = (function () {

        var staggerVisualizerEl = document.querySelector('.stagger-visualizer');
        var dotsWrapperEl = staggerVisualizerEl.querySelector('.dots-wrapper');
        var dotsFragment = document.createDocumentFragment();
        var grid = [10, 20];
        var cell = 55;
        var numberOfElements = grid[0] * grid[1];
        var animation;
        var paused = true;

        fitElementToParent(staggerVisualizerEl, 0);

        for (var i = 0; i < numberOfElements; i++) {
            var dotEl = document.createElement('div');
            dotEl.classList.add('dot');
            dotsFragment.appendChild(dotEl);
        }

        dotsWrapperEl.appendChild(dotsFragment);

        var index = anime.random(0, numberOfElements - 1);
        var nextIndex = 0;

        anime.set('.stagger-visualizer .cursor', {
            translateX: anime.stagger(-cell, {
                grid: grid,
                from: index,
                axis: 'x'
            }),
            translateY: anime.stagger(-cell, {
                grid: grid,
                from: index,
                axis: 'y'
            }),
            translateZ: 0,
            scale: 1.5,
        });

        function play() {

            paused = false;
            if (animation) animation.pause();

            nextIndex = anime.random(0, numberOfElements - 1);

            animation = anime.timeline({
                    easing: 'easeInOutQuad',
                    complete: play
                })
                .add({
                    targets: '.stagger-visualizer .cursor',
                    keyframes: [{
                            scale: .75,
                            duration: 120
                        },
                        {
                            scale: 2.5,
                            duration: 220
                        },
                        {
                            scale: 1.5,
                            duration: 450
                        },
                    ],
                    duration: 300
                })
                .add({
                    targets: '.stagger-visualizer .dot',
                    keyframes: [{
                        translateX: anime.stagger('-2px', {
                            grid: grid,
                            from: index,
                            axis: 'x'
                        }),
                        translateY: anime.stagger('-2px', {
                            grid: grid,
                            from: index,
                            axis: 'y'
                        }),
                        duration: 100
                    }, {
                        translateX: anime.stagger('4px', {
                            grid: grid,
                            from: index,
                            axis: 'x'
                        }),
                        translateY: anime.stagger('4px', {
                            grid: grid,
                            from: index,
                            axis: 'y'
                        }),
                        scale: anime.stagger([2.6, 1], {
                            grid: grid,
                            from: index
                        }),
                        duration: 225
                    }, {
                        translateX: 0,
                        translateY: 0,
                        scale: 1,
                        duration: 1200,
                    }],
                    delay: anime.stagger(80, {
                        grid: grid,
                        from: index
                    })
                }, 30)
                .add({
                    targets: '.stagger-visualizer .cursor',
                    translateX: {
                        value: anime.stagger(-cell, {
                            grid: grid,
                            from: nextIndex,
                            axis: 'x'
                        })
                    },
                    translateY: {
                        value: anime.stagger(-cell, {
                            grid: grid,
                            from: nextIndex,
                            axis: 'y'
                        })
                    },
                    scale: 1.5,
                    easing: 'cubicBezier(.075, .2, .165, 1)'
                }, '-=800')
            index = nextIndex;
        }

        play();

    })();
});

// callback steps
$(() => {
    $('.step .btn').on('click', function() {
        const $currStep = $(this).parents('.step');
        const $nextStep = $currStep.next('.step');

        if ($nextStep.length) {
            $currStep.fadeOut(300, () => {
                $nextStep.fadeIn();
                $(`.steps__progress span:nth-child(${$nextStep.index() + 1})`).addClass('active');
            });
        } else {
            // $('.steps__progress').fadeOut(300);
            // $currStep.fadeOut(300, () => {
            //     $('.success').fadeIn();
            // });
        }
    });
});

// animate gradient
$(() => {
    
    var colors = new Array(
        [255,87,34],
        [238,55,103],
        [186,68,143],
        [118,83,151],
        [61,83,127],
        [47,72,88]
    );

    // var colors = new Array(
    //     [255,87,34],
    //     [205,47,80],
    //     [132,42,89],
    //     [62,37,65],
    //     [17,17,17]
    // );

    var step = 0;
    //color table indices for: 
    // current color left
    // next color left
    // current color right
    // next color right
    var colorIndices = [0, 1, 2, 3];

    //transition speed
    var gradientSpeed = 0.002;

    function updateGradient() {
        if ($ === undefined) return;

        var c0_0 = colors[colorIndices[0]];
        var c0_1 = colors[colorIndices[1]];
        var c1_0 = colors[colorIndices[2]];
        var c1_1 = colors[colorIndices[3]];

        var istep = 1 - step;
        var r1 = Math.round(istep * c0_0[0] + step * c0_1[0]);
        var g1 = Math.round(istep * c0_0[1] + step * c0_1[1]);
        var b1 = Math.round(istep * c0_0[2] + step * c0_1[2]);
        var color1 = "rgb(" + r1 + "," + g1 + "," + b1 + ")";

        var r2 = Math.round(istep * c1_0[0] + step * c1_1[0]);
        var g2 = Math.round(istep * c1_0[1] + step * c1_1[1]);
        var b2 = Math.round(istep * c1_0[2] + step * c1_1[2]);
        var color2 = "rgb(" + r2 + "," + g2 + "," + b2 + ")";

        $('.section.services').css({
            background: "-webkit-gradient(linear, left top, right top, from(" + color1 + "), to(" + color2 + "))"
        }).css({
            background: "-moz-linear-gradient(left, " + color1 + " 0%, " + color2 + " 100%)"
        });

        step += gradientSpeed;
        if (step >= 1) {
            step %= 1;
            colorIndices[0] = colorIndices[1];
            colorIndices[2] = colorIndices[3];

            //pick two new target color indices
            //do not pick the same as the current one
            colorIndices[1] = (colorIndices[1] + Math.floor(1 + Math.random() * (colors.length - 1))) % colors.length;
            colorIndices[3] = (colorIndices[3] + Math.floor(1 + Math.random() * (colors.length - 1))) % colors.length;
        }
    }

    // setInterval(updateGradient, 10);
});