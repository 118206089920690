import 'bootstrap';
import 'nouislider/dist/nouislider.min.css';
import 'swiper/swiper.scss';
import 'simplebar/dist/simplebar.css';
import '@fancyapps/ui/dist/fancybox.css';
import 'fullpage.js/dist/fullpage.min.css';

import 'simplebar';
import './scripts';
import './devices/devices.scss';
import './style.scss';
